import React, { FormEvent } from 'react';
import AppContext from './AppContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ChatTermin from './ChatTermin';
import axios from 'axios';

export interface ChatOfflineModalProps {
    closeCallback: () => void;
    updateCallback: () => void;
}

interface ChatOfflineModal {
    state: ChatOfflineModalState;
    props: ChatOfflineModalProps;
    closeCallback: () => void;
    updateCallback: () => void;
    drpTypeRef: React.RefObject<HTMLSelectElement>|null;
    listState: Boolean;
}

interface ChatTerminData {
    id:number;
    desc:string;
    startDate:string;
    startTime:string;
    endDate:string;
    endTime:string;
}

interface ChatOfflineModalState {
    infoMessage:string;
    successMessage:string;
    errorMessage:string;
    formstatus:string;
    offlineMessage:string;
    nextTerminDesc:string;
    nextTerminStartDate:string;
    nextTerminStartTime:string;
    nextTerminEndDate:string;
    nextTerminEndTime:string;
    nextTerminType:string;
    termine:Array<ChatTerminData>;
    terminToDeleteID:number;
}

class ChatOfflineModal extends React.Component {
    static contextType = AppContext;

    constructor(props:ChatOfflineModalProps) {
        super(props);
        this.closeCallback = props.closeCallback;
        this.updateCallback = props.updateCallback;

        this.state = {
            infoMessage: "",
            successMessage: "",
            errorMessage: "",
            formstatus: "loading",
            offlineMessage: "",
            nextTerminDesc: "",
            nextTerminStartDate: "",
            nextTerminStartTime: "",
            nextTerminEndDate: "",
            nextTerminEndTime: "",
            nextTerminType: "",
            termine:[],
            terminToDeleteID: 0
        }

        this.drpTypeRef = React.createRef();
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({formstatus: "loading"});
    
        var jsonurl = "https://guppy.site/chatOfflineInfoLoad.aspx?ProducerID=" + encodeURIComponent((this.context as any).loginData.producerID);
    
        //const axios = require('axios').default;
    
        axios.get(jsonurl).then((response:any) => {
            let data = response.data;

            if(data.result === "ok") {
                if(this.listState) {
                    this.setState({formstatus: "list"});
                } else {
                    this.setState({formstatus: "ready"});
                }

                this.setState({
                    offlineMessage: data.offlineMessage,
                    termine: data.termine
                });
            } else {
                this.setState({formstatus: "error"});
                this.setState({errorMessage: "Error loading data: " + data.error});
            }
        })
        .catch((error:any) => {
            this.setState({formstatus: "error"});
            this.setState({errorMessage: error.toString()});
        });
    }

    handleAddTermin(event:React.ChangeEvent<HTMLFormElement>) {
        event.preventDefault();

        this.setState({successMessage: ""});
        this.setState({infoMessage: ""});
        this.setState({errorMessage: ""});
        this.setState({formstatus: "SaveTermin"});

        var jsonurl = "https://guppy.site/chatOfflineInfoSave.aspx?Action=SaveTermin&ProducerID=" + encodeURIComponent((this.context as any).loginData.producerID);

        jsonurl += "&desc=" + encodeURIComponent(this.state.nextTerminDesc);
        jsonurl += "&startDate=" + encodeURIComponent(this.state.nextTerminStartDate);
        jsonurl += "&startTime=" + encodeURIComponent(this.state.nextTerminStartTime);
        jsonurl += "&endDate=" + encodeURIComponent(this.state.nextTerminEndDate);
        jsonurl += "&endTime=" + encodeURIComponent(this.state.nextTerminEndTime);
    
        //const axios = require('axios').default;
    
        axios.get(jsonurl).then((response:any) => {
            let data = response.data;
            console.log("chatOfflineInfoSave result: " + data.result + " | error: " + data.error);
            if(data.result === "ok") {
                this.setState({successMessage: this.getText(data.error)});
                this.listState = true;
                this.handleReload();
            } else {
                this.setState({infoMessage: this.getText(data.error)});
                this.setState({formstatus: "ready"});
            }
        })
        .catch((error:any) => {
          this.setState({errorMessage: error.toString()});
          this.setState({formstatus: "ready"});
        });
    }

    showTermine() {
        this.listState = true;
        this.setState({successMessage: ""});
        this.setState({infoMessage: ""});
        this.setState({errorMessage: ""});
        this.setState({formstatus: "list"});
    }

    showForm() {
        this.listState = false;
        this.setState({successMessage: ""});
        this.setState({infoMessage: ""});
        this.setState({errorMessage: ""});
        this.setState({formstatus: "ready"});
    }

    handleReload() {
        this.loadData();
    }

    handleSaveOfflineMessage(event:React.ChangeEvent<HTMLFormElement>) {
        event.preventDefault();

        this.setState({successMessage: ""});
        this.setState({infoMessage: ""});
        this.setState({errorMessage: ""});
        this.setState({formstatus: "SaveOfflineMessage"});

        var jsonurl = "https://guppy.site/chatOfflineInfoSave.aspx?Action=SaveOfflineMessage&ProducerID=" + encodeURIComponent((this.context as any).loginData.producerID);

        jsonurl += "&offlineMessage=" + encodeURIComponent(this.state.offlineMessage);
    
        //const axios = require('axios').default;
    
        axios.get(jsonurl).then((response:any) => {
            let data = response.data;
            console.log("chatOfflineInfoSave result: " + data.result + " | error: " + data.error);
            if(data.result === "ok") {
                this.setState({successMessage: this.getText(data.error)});
                this.setState({formstatus: "ready"});
            } else {
                this.setState({infoMessage: this.getText(data.error)});
                this.setState({formstatus: "ready"});
            }
        })
        .catch((error:any) => {
          this.setState({errorMessage: error.toString()});
          this.setState({formstatus: "ready"});
        });
    }

    deleteTermin(id:number) {
        this.setState({terminToDeleteID:id});
    }

    handleTypeChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({nextTerminType: event.target.value});
    }

    handleOfflineMessageChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({offlineMessage: event.target.value});
    }

    handleNextTerminDescChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({nextTerminDesc: event.target.value});
    }

    handleNextTerminStartDateChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({nextTerminStartDate: event.target.value});
    }

    handleNextTerminStartTimeChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({nextTerminStartTime: event.target.value});
    }

    handleNextTerminEndDateChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({nextTerminEndDate: event.target.value});
    }

    handleNextTerminEndTimeChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({nextTerminEndTime: event.target.value});
    }

    confirmDelete() {
        this.deleteTerminCallInterface(this.state.terminToDeleteID);
    }

    cancelDelete() {
        this.setState({terminToDeleteID:0});
    }

    deleteTerminCallInterface = async(id:number) => {
        //const axios = require('axios').default;

        this.setState({estatus:""});

        let url:string = "https://guppy.site/chatOfflineInfoSave.aspx?action=DeleteTermin&id=" + encodeURIComponent(id) + "&producerID=" + encodeURIComponent((this.context as any).loginData.producerID);
    
        try {
            const response = await axios.get(url);
            if(response.data.result === "ok") {
                this.setState({terminToDeleteID:0});
                this.listState = true;
                this.handleReload();
                this.setState({successMessage:this.getText("deleted")});
            } else {
                this.setState({errorMessage:response.data.error});
            }
        } catch (error) {
            this.setState({errorMessage:"error deleting termin "});
        }
    }

    getText(exp:string) {
        if((this.context as any).language === "de") {
            if(exp === "Möchtest Du eine Nachricht für Deine User hinterlassen?") {
                return "Du kannst hier eine Nachricht für Besucher von Deinem Profil hinterlassen, während Du offline bist, zusätzlich kannst Du Termine eintragen, an denen Du wieder online sein wirst.";
            }
            if(exp === "Close") {
                return "Schliessen";
            }
            if(exp === "OfflineMessage") {
                return "Nachricht für Besucher von Deinem Profil";
            }
            if(exp === "When are you going to be online again?") {
                return "Wann wirst Du das nächste Mal online sein?";
            }
            if(exp === "inserted") {
                return "Termin eingetragen";
            }
            if(exp === "changed") {
                return "Änderung gespeichert";
            }
            if(exp === "unchanged") {
                return "Keine Änderung";
            }
            if(exp === "deleted") {
                return "Termin gelöscht";
            }
            if(exp === "Zurück") {
                return "Zurück";
            }
            if(exp === "Close") {
                return "Schliessen";
            }
        } else if((this.context as any).language === "en") {
            if(exp === "Möchtest Du eine Nachricht für Deine User hinterlassen?") {
                return "You can leave a message and your next online dates for visitiors of your profile";
            }
            if(exp === "Offline-Nachricht") {
                return "Offline-Note";
            }
            if(exp === "OfflineMessage") {
                return "Message for visitors of your profile";
            }
            if(exp === "Nachricht speichern") {
                return "Save message";
            }
            if(exp === "Terminbeschreibung") {
                return "Comment"
            }
            if(exp === "Datum") {
                return "Day";
            }
            if(exp === "Uhrzeit") {
                return "Time of day";
            }
            if(exp === "Terminart") {
                return "Kind of appointment";
            }
            if(exp === "inserted") {
                return "Schnedule inserted";
            }
            if(exp === "changed") {
                return "Message changed";
            }
            if(exp === "unchanged") {
                return "No change";
            }
            if(exp === "deleted") {
                return "Schedule deleted";
            }
            if(exp === "Zurück") {
                return "Back";
            }
        }
    
        return exp;
    }

    render() {
        return(
            <Modal show={true} onHide={this.closeCallback.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.getText("Offline-Nachricht")}</Modal.Title>
                </Modal.Header>
          
                <Modal.Body>
                    <p><small>{this.getText("Möchtest Du eine Nachricht für Deine User hinterlassen?")}</small></p>
                    { this.state.infoMessage === "" ? null : <Alert variant="info">{this.state.infoMessage}</Alert>}
                    { this.state.successMessage === "" ? null : <Alert variant="success">{this.state.successMessage}</Alert>}
                    { this.state.errorMessage === "" ? null : <Alert variant="danger">{this.state.errorMessage}</Alert>}
                    { this.state.terminToDeleteID > 0 ? <div className="mb-2"><Alert variant="info">{this.getText("Wirklich diesen Termin löschen?")}</Alert><Row><Col><Button className="w-100" variant="danger" onClick={this.confirmDelete.bind(this)}>{this.getText("Löschen")}</Button></Col><Col><Button className="w-100" variant="secondary" onClick={this.cancelDelete.bind(this)}>{this.getText("Abbrechen")}</Button></Col></Row><hr></hr></div> : null }
                    { this.state.terminToDeleteID === 0 && (this.state.formstatus === "ready" || this.state.formstatus === "SaveOfflineMessage" || this.state.formstatus === "SaveTermin") ?
                    <div>
                        <Form onSubmit={this.handleSaveOfflineMessage.bind(this)}>
                            <Form.Group>
                                <Form.Label>{this.getText("OfflineMessage")}</Form.Label>
                                <Form.Control type="text" placeholder={this.getText("OfflineMessage")} value={this.state.offlineMessage} onChange={this.handleOfflineMessageChange.bind(this)} />
                            </Form.Group>

                            <Button variant="primary" type="submit" disabled={ this.state.formstatus === "ready" ? false : true }>{ this.state.formstatus === "SaveOfflineMessage" ? "Saving..." : this.getText("Nachricht speichern")}</Button>
                        </Form>

                            <hr></hr>

                        <Form onSubmit={this.handleAddTermin.bind(this)}>
                            <h5>{this.getText("When are you going to be online again?")}</h5>

                            <Form.Group>
                                <Form.Label>{this.getText("Terminbeschreibung")}</Form.Label>
                                <Form.Control type="text" placeholder={this.getText("Terminbeschreibung")} value={this.state.nextTerminDesc} onChange={this.handleNextTerminDescChange.bind(this)} />
                            </Form.Group>

                            <div className="row">
                                <div className="col-8">
                                    <Form.Group>
                                        <Form.Label>Start-{this.getText("Datum")}</Form.Label>
                                        <Form.Control type="text" placeholder="dd.MM.yyyy" value={this.state.nextTerminStartDate} onChange={this.handleNextTerminStartDateChange.bind(this)} />
                                    </Form.Group>
                                </div>
                                <div className="col-4">
                                    <Form.Group>
                                        <Form.Label>Start-{this.getText("Uhrzeit")}</Form.Label>
                                        <Form.Control type="text" placeholder="HH:mm" value={this.state.nextTerminStartTime} onChange={this.handleNextTerminStartTimeChange.bind(this)} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <Form.Group>
                                        <Form.Label>End-{this.getText("Datum")}</Form.Label>
                                        <Form.Control type="text" placeholder="dd.MM.yyyy" value={this.state.nextTerminEndDate} onChange={this.handleNextTerminEndDateChange.bind(this)} />
                                    </Form.Group>
                                </div>
                                <div className="col-4">
                                    <Form.Group>
                                        <Form.Label>End-{this.getText("Uhrzeit")}</Form.Label>
                                        <Form.Control type="text" placeholder="HH:mm" value={this.state.nextTerminEndTime} onChange={this.handleNextTerminEndTimeChange.bind(this)} />
                                    </Form.Group>
                                </div>
                            </div>

                            <Form.Group>
                                <Form.Label>{this.getText("Terminart")}</Form.Label>
                                <Form.Control id="drpType" ref={this.drpTypeRef} as="select" onChange={this.handleTypeChange.bind(this)}>
                                    <option value="einzel">Einzeltermin</option>
                                    <option value="serie">Serientermin</option>
                                </Form.Control>
                            </Form.Group>

                            <Button variant="primary" className="mr-2" type="submit" disabled={ this.state.formstatus === "ready" ? false : true }>{this.state.formstatus === "SaveTermin" ? "Saving..." : this.getText("Termin eintragen")}</Button>
                            <Button variant="secondary" type="button" onClick={this.showTermine.bind(this)} disabled={ this.state.formstatus === "ready" ? false : true }>{this.getText("Termine anzeigen")}</Button>
                        </Form>
                    </div> : null
                    }
                    { this.state.formstatus === "list" ? <div>{ this.state.termine.length === 0 ? this.getText("Keine Termine gefunden") : this.state.termine.map((obj:ChatTerminData,index:any) => <ChatTermin key={index} deleteCallback={this.deleteTermin.bind(this)} id={obj.id} desc={obj.desc} startDate={obj.startDate} endDate={obj.endDate} startTime={obj.startTime} endTime={obj.endTime} /> )}</div> : null }
                    { this.state.formstatus === "loading" ? <div>{this.getText("Loading")}</div> : null }
                </Modal.Body>
            
                <Modal.Footer>
                    { this.state.formstatus === "list" ? <Button variant="secondary" type="button" onClick={this.showForm.bind(this)}>{this.getText("Zurück")}</Button> : <Button variant="secondary" onClick={this.closeCallback.bind(this)}>{this.getText("Close")}</Button> }
                    <Button variant="primary" onClick={this.handleReload.bind(this)}>{this.getText("Reload")}</Button>
                </Modal.Footer>
          </Modal>
        )
    }
}

export default ChatOfflineModal;