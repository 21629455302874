import React, { FunctionComponent,useEffect, useState } from "react";
import { PlayerUiState, PlayerUiContext, types, VideoClient } from "@video/video-client-web";
import Player from "./Player";
import { PlayerOptions } from "@video/video-client-core/lib/api";
import { WebrtcPlayerOptions } from "@video/video-client-core/lib/internal/player/webrtc";
import { CorePlayerOptions } from "@video/video-client-core/lib/internal/player/core";
import { ManifestPlayerOptions } from "@video/video-client-core/lib/internal/player/manifest";



type ModularPlayerProps = {
  manifestUrl: string;
  stagingMode: boolean;
  blurred: boolean;
}


interface ModularPlayer {
  props: ModularPlayerProps;
}

export const ModularPlayer: FunctionComponent<ModularPlayerProps> = ({ manifestUrl,stagingMode,blurred }) => {
    const yourDomain = stagingMode ? "https://manifest2.guppy-staging.devspace.lsea3.generflow.com" : "https://guppy-prod-euw1d.video.tv";

    const [vc, setVc] = useState<types.VideoClientAPI| null>(null);
    const [playerUi, setPlayerUi] = useState<PlayerUiState | null>(null);
    const [audioEnabled, setAudioEnabled] = useState<boolean|null>(null);

    const debuglog = (msg:string) => {
      if(typeof(console) != "undefined") {
        console.log(msg);
      }
    }

    useEffect(() => {
      /*
      * 4.3a) Setup a VideoClient instance. Be sure to include a state check to avoid
      * an infinite loop.
      */
      if (vc == null) {
        const opts: types.VideoClientOptions = {
          userId: "",
          backendEndpoints: [yourDomain],
          playerOptions: {
            autoPlay: true,
            bitrate: 400000,
        }
          
        };
      /*
      * 4.3b) Set local state.
      */
        const newVc = new VideoClient(opts);
        setVc(newVc);
      }
      /*
      * 4.3c) On unmount, be sure to dispose the VideoClient and set state to `null`.
      */
      return () => {
        if (vc != null) {
          vc.dispose();
          setVc(null);
        }
      };
    }, [vc]);

    useEffect(() => {
      /*
      * 7.2a. Check if playerUi is null to avoid a loop.
      */
      if (vc != null && playerUi == null && manifestUrl) {
        //const drivers: types.PlayerSpecList = [{ id: "webrtc" }];
          const options = {
            autoPlay: true,
            volume: 100,
            muted: false,
            displayPoster: true,
            mutedAutoplayFallback: true,
            blurred: blurred,
            urlParams: {
              vdc: blurred,
              substitute: blurred,
              sbp: blurred
            }
          }; // Your PlayerOptions
          console.log("ModularPlayer manifestUrl: " + manifestUrl);
          const player = vc.requestPlayer(manifestUrl, options);
          setPlayerUi(new PlayerUiState(player));
          return () => {
            if (playerUi != null) {
              let myPlayerUI:PlayerUiState = playerUi as PlayerUiState;
              if(myPlayerUI) {
                myPlayerUI.dispose();
              }
              setPlayerUi(null);
            }
          };
      }
    }, [vc, playerUi, manifestUrl]);

    /*
    * Let's add some Ui to remind us to include a manifestUrl
    */
    if (!manifestUrl) {
      return <h3>Please input a valid manifest url.</h3>;
    }

    if (playerUi == null) {
      return <></>;
    }

    return (
    <PlayerUiContext.Provider value={playerUi}>
        <Player />
    </PlayerUiContext.Provider>
    );
};

export default ModularPlayer;