import React from 'react';
import AppContext from './AppContext';
import {guppySoupClient, guppySoupConsumerParameters} from './guppySoupTypes';
import {guppySoupPeer} from './guppySoupTypes';
import {guppySoupConsumer} from './guppySoupTypes';

interface Cam2CamVideo {
    state:Cam2CamVideoState;
    props:Cam2CamVideoProps;
    closeCallback(userPseudo:string,streamname:string,flashChatSessionID:string):void;
    checkCounter:number;
    sizeChangeTrackCounter:number;
    dragStartX:number;
    dragStartY:number;
    videoElement:React.RefObject<HTMLVideoElement>;
    videoElementContainer:React.RefObject<HTMLDivElement>;
    sysMessage(msg:string):void;
    index:number;
    checkCam2CamStatusTimerEnabled:boolean;
    checkCam2CamStatusTimer:NodeJS.Timer;
    Client:guppySoupClient;
    peer:any;
    lastPlayTime:number;
}

interface Cam2CamVideoState {
    userPseudo:string;
    streamname:string;
    stateMsg:string;
    flashChatSessionID:string;
    streamFound:boolean;
    videoPlaying:boolean;
    soupNum:number;
    userPeerId:string;
    orderIndex:number;
    divTop:number;
    divLeft:number;
    videoWidth:number;
    videoHeight:number;
    opacity:string;
}

interface Cam2CamVideoProps {
    userPseudo:string,
    streamname:string,
    flashChatSessionID:string,
    closeCallback(userPseudo:string,streamname:string,flashChatSessionID:string):void,
    sysMessage(msg:string):void,
    index:number,
    orderIndex:number;
    client:guppySoupClient;
}

class Cam2CamVideo extends React.Component {
    static contextType = AppContext;

    constructor(props:Cam2CamVideoProps) {
        super(props);

        this.state = {
            userPseudo: props.userPseudo,
            streamname: props.streamname,
            stateMsg: '',
            flashChatSessionID: props.flashChatSessionID,
            streamFound: false,
            videoPlaying: false,
            soupNum: 0,
            userPeerId: '',
            orderIndex: props.orderIndex,
            divTop: 180 + (props.orderIndex * 20),
            divLeft: 10 + (props.orderIndex * 20),
            videoWidth: 240,
            videoHeight: 180,
            opacity: "1.0",
        };

        this.closeCallback = props.closeCallback;
        this.checkCounter = 0;
        this.sizeChangeTrackCounter = 0;

        this.Client = props.client;
        this.peer = null;
        this.dragStartX = 0;
        this.dragStartY = 0;
        this.videoElement = React.createRef();
        this.videoElementContainer = React.createRef();
        this.lastPlayTime = 0.0;
        this.sysMessage = props.sysMessage.bind(this);
        this.index = props.index;
    }

    componentDidMount() {
        this.pollSoupCam2Cam();
        if(!this.checkCam2CamStatusTimerEnabled) {
            this.checkCam2CamStatusTimer = setInterval(this.pollSoupCam2Cam,5000);
            this.checkCam2CamStatusTimerEnabled = true;
        }
    }

    componentWillUnmount() {
        if(this.checkCam2CamStatusTimerEnabled) {
            clearInterval(this.checkCam2CamStatusTimer as any);
            this.checkCam2CamStatusTimerEnabled = false;
        }
        if(this.peer) {
            if(this.peer.soupNum === 2) {
                try {
                    this.sysMessage("Unsubscribing from cam-2-cam track " + this.peer.id);
                    this.Client.unsubscribeFromTrackSecond(this.peer.id, "cam-video");
                } catch(e:any) {
                    this.sysMessage("Error unsubscribing from cam-2-cam track " + e.toString());
                    return;
                }
            } else if(this.peer.soupNum === 3) {
                try {
                    this.sysMessage("Unsubscribing from cam-2-cam track " + this.peer.id);
                    this.Client.unsubscribeFromTrackThird(this.peer.id, "cam-video");
                } catch(e:any) {
                    this.sysMessage("Error unsubscribing from cam-2-cam track " + e.toString());
                    return;
                }
            } else {
                try {
                    this.sysMessage("Unsubscribing from cam-2-cam track " + this.peer.id);
                    this.Client.unsubscribeFromTrack(this.peer.id, "cam-video");
                } catch(e:any) {
                    this.sysMessage("Error unsubscribing from cam-2-cam track " + e.toString());
                    return;
                }
            }
        }
    }

    handleDrag(ev:React.DragEvent<HTMLDivElement>) {
        let myTarget:HTMLDivElement = ev.target as HTMLDivElement;
        if(myTarget !== null) {
            this.dragStartX = ev.pageX - myTarget.offsetLeft;
            this.dragStartY = ev.pageY - myTarget.offsetTop;
        }
        this.setState({opacity: "0.4"});
    }

    handleDragEnd(ev:React.DragEvent<HTMLDivElement>) {
        this.setState({divLeft: ev.pageX - this.dragStartX});
        this.setState({divTop: ev.pageY - this.dragStartY});
        this.setState({opacity: "1.0"});
    }

    pollSoupCam2Cam = async () => {
        let Client = this.Client;
        let webrtcNick = (this.context as any).loginData.pseudo;

        if(!Client.joined && (this.context as any).enableSoup1) {
            await Client.joinRoom();
        }
        if(!Client.secondJoined && (this.context as any).enableSoup2) {
            await Client.joinSecondRoom();
        }
        if(!Client.thirdJoined && (this.context as any).enableSoup3) {
            await Client.joinThirdRoom();
        }

        if(this.state.videoPlaying) {
            let video = this.videoElement.current;

            if(video) {
                if(this.lastPlayTime === video.currentTime) {
                    this.sysMessage("cam-2-cam video seems stalled");
                    this.setState({videoPlaying: false});
                    this.setState({stateMsg: 'seemsstalled'});
                    /*this.sysMessage("Unsubscribing from cam-2-cam video track " + this.state.userPeerId + " on soup " + this.state.soupNum);
                    if(this.state.soupNum === 2) {
                        try {
                            Client.unsubscribeFromTrackSecond(this.state.userPeerId, "cam-video");
                        } catch(e:any) {
                            this.sysMessage("Error unsubscribing from cam-2-cam track " + e.toString());
                            return;
                        }
                    } else if(this.state.soupNum === 3) {
                        try {
                            Client.unsubscribeFromTrackThird(this.state.userPeerId, "cam-video");
                        } catch(e:any) {
                            this.sysMessage("Error unsubscribing from cam-2-cam track " + e.toString());
                            return;
                        }
                    } else if(this.state.soupNum === 1) {
                        try {
                            Client.unsubscribeFromTrack(this.state.userPeerId, "cam-video");
                        } catch(e:any) {
                            this.sysMessage("Error unsubscribing from cam-2-cam track " + e.toString());
                            return;
                        }
                    }*/
                }
                this.lastPlayTime = video.currentTime;
            }
        }

        if(new String(webrtcNick).indexOf("Testsender") > -1 && !this.state.videoPlaying) {
            this.sysMessage("pollCam2Cam looking for " + this.state.userPseudo + " | " + this.state.streamname);
        }

        let searchPeerID = new String(this.state.streamname);

        if((this.context as any).enableSoup1) {
            for (let peer of Client.sortPeers(Client.lastPollSyncData)) {
                if (peer.id === Client.myPeerId) {
                    continue;
                }
                for (let [mediaTag, info] of Object.entries(peer.media)) {
                    if(searchPeerID.localeCompare(peer.id) === 0) {
                        if(new String(webrtcNick).indexOf("Testsender") > -1 && !this.state.videoPlaying) {
                            this.sysMessage("Found Cam2Cam peer " + peer.id + " -> " + this.state.userPseudo + " on soup1");
                        }
                        this.setState({streamFound: true});
                        if(!this.state.videoPlaying) {
                            //this.sysMessage("Found Cam-2-Cam client " + this.state.userPseudo);
                            this.addSoupCam2Cam({
                                id: peer.id,
                                nickName: this.state.userPseudo,
                                flashChatSessionID: this.state.flashChatSessionID,
                                soupNum: 1
                            });
                        }
                        return;
                    }
                }
            }
        }
    
        if((this.context as any).enableSoup2) {
            for (let peer of Client.sortPeers(Client.secondPollSyncData)) {
                if (peer.id === Client.secondPeerID) {
                    continue;
                }
                for (let [mediaTag, info] of Object.entries(peer.media)) {
                    if(searchPeerID.localeCompare(peer.id) === 0) {
                        if(new String(webrtcNick).indexOf("Testsender") > -1 && !this.state.videoPlaying) {
                            this.sysMessage("Found Cam2Cam peer " + peer.id + " -> " + this.state.userPseudo + " on soup2");
                        }
                        this.setState({streamFound: true});
                        if(!this.state.videoPlaying) {
                            //this.sysMessage("Found Cam-2-Cam client " + this.state.userPseudo);
                            this.addSoupCam2Cam({
                                id: peer.id,
                                nickName: this.state.userPseudo,
                                flashChatSessionID: this.state.flashChatSessionID,
                                soupNum: 2
                            });
                        } else {
                            this.sysMessage("Found Cam2Cam peer " + peer.id + " -> " + this.state.userPseudo + " but already playing");
                        }
                        return;
                    }
                }
            }
        }
    
        if((this.context as any).enableSoup3) {
            for (let peer of Client.sortPeers(Client.thirdPollSyncData)) {
                if (peer.id === Client.thirdPeerID) {
                    continue;
                }
                for (let [mediaTag, info] of Object.entries(peer.media)) {
                    if(searchPeerID.localeCompare(peer.id) === 0) {
                        if(new String(webrtcNick).indexOf("Testsender") > -1 && !this.state.videoPlaying) {
                            this.sysMessage("Found Cam2Cam peer " + peer.id + " -> " + this.state.userPseudo + " on soup3");
                        }
                        this.setState({streamFound: true});
                        if(!this.state.videoPlaying) {
                            //this.sysMessage("Found Cam-2-Cam client " + this.state.userPseudo);
                            this.addSoupCam2Cam({
                                id: peer.id,
                                nickName: this.state.userPseudo,
                                flashChatSessionID: this.state.flashChatSessionID,
                                soupNum: 3
                            });
                        }
                        return;
                    }
                }
            }
        }
    }

    sleep = async(ms:number) => {
        return new Promise<void>((r) => setTimeout(() => r(), ms));
    }
    
    addSoupCam2Cam = async(peer:guppySoupPeer) => {
        let Client = this.Client;
        let consumer:guppySoupConsumer;

        if(peer) {
            if(typeof(peer.id) === "undefined") {
                this.sysMessage("cam-2-cam peer id is undefined");
                return;
            } else {
                if(peer.id === "") {
                    this.sysMessage("cam-2-cam peer id is empty");
                    return;
                }
            }
        }

        //this.sysMessage("adding peer video element");
        this.peer = peer;
    
        if(peer.soupNum === 2) {
            try {
                //consumer = await Client.subscribeToTrackSecond(peer.id, "cam-video");
                let peerId = peer.id;
                let mediaTag = "cam-video";

                this.sysMessage('subscribe to track ' + peerId + " " + mediaTag);
    
                // create a receive transport if we don't already have one
                if (Client.secondRecvTransport) {
                    if (Client.secondRecvTransport.connectionState == "disconnected") {
                        Client.secondRecvTransport = await Client.createSecondTransport('recv');
                    }
                } else {
                    Client.secondRecvTransport = await Client.createSecondTransport('recv');
                }
            
                // if we do already have a consumer, we shouldn't have called this method
                consumer = Client.findConsumerForTrackSecond(peerId, mediaTag);
                if (consumer) {
                    console.error('already have consumer for track', peerId, mediaTag);
                    await Client.resumeConsumerSecond(consumer);
                } else {
                    // ask the server to create a server-side consumer object and send
                    // us back the info we need to create a client-side consumer
                    let consumerParameters:guppySoupConsumerParameters = await Client.secondSig('recv-track', {
                        mediaTag,
                        mediaPeerId: peerId,
                        rtpCapabilities: Client.secondDevice.rtpCapabilities
                    });
                    this.sysMessage('consumer parameters ' + consumerParameters);
                    consumer = await Client.secondRecvTransport.consume({
                        ...consumerParameters,
                        appData: { peerId, mediaTag }
                    });
                    this.sysMessage('created new consumer ' + consumer.id);
                
                    // the server-side consumer will be started in paused state. wait
                    // until we're connected, then send a resume request to the server
                    // to get our first keyframe and start displaying video
                    while (Client.secondRecvTransport.connectionState !== 'connected') {
                        this.sysMessage('transport connstate ' + Client.secondRecvTransport.connectionState);
                        await this.sleep(100);
                    }

                    this.sysMessage('transport connstate ' + Client.secondRecvTransport.connectionState + " -> calling resumeConsumer");

                    // okay, we're ready. let's ask the peer to send us media
                    await Client.resumeConsumerSecond(consumer);
                
                    // keep track of all our consumers
                    Client.secondConsumers.push(consumer);

                    this.sysMessage("consumer.id: " + consumer.id + " | consumer.closed: " + consumer.closed + " | consumer.kind: " + consumer.kind);
                    this.sysMessage("consumer.track: " + consumer.track);
                    this.sysMessage("consumer.track.readyState: " + consumer.track.readyState);
                }
            } catch(e:any) {
                this.sysMessage("error creating cam-2-cam consumer for peer: " + peer.id + " on soup2 |" + e.toString());
                /*try {
                    Client.secondRecvTransport && await Client.secondRecvTransport.close();
                } catch (e) {
                  console.error(e);
                }*/
                return;
            }
        } else if(peer.soupNum === 3) {
            try {
                consumer = await Client.subscribeToTrackThird(peer.id, "cam-video");
            } catch(e:any) {
                this.sysMessage("error creating cam-2-cam consumer for peer: " + peer.id + " on soup3 |" + e.toString());
                /*try {
                    Client.thirdRecvTransport && await Client.thirdRecvTransport.close();
                } catch (e) {
                  console.error(e);
                }*/
                return;
            }
        } else {
            try {
                consumer = await Client.subscribeToTrack(peer.id, "cam-video");
            } catch(e:any) {
                this.sysMessage("error creating cam-2-cam consumer for peer: " + peer.id + " on soup1 |" + e.toString());
                /*try {
                    Client.recvTransport && await Client.recvTransport.close();
                } catch (e) {
                  console.error(e);
                }*/
                return;
            }
        }
    
        if (!(consumer && consumer.track)) {
            this.sysMessage("we have an invalid cam-2-cam consumer for peer: " + peer.id + "|" + peer.nickName);
            return;
        }

        this.setState({
            soupNum: peer.soupNum,
            userPeerId: peer.id
        });

        let video = this.videoElement.current;

        if(!video) {
            return;
        }

        video.muted = true;
        
        video.setAttribute('width', '100%');
        video.setAttribute('height', '100%');
    
        video.setAttribute('loop', '');
        video.setAttribute('autoplay', 'true');
        video.setAttribute('playsinline', 'true');
        
        video.style.position = "absolute";
        video.style.top = "0px";
        video.style.left = "0px";
    
        var vidcontainer:HTMLDivElement = this.videoElementContainer.current!;
        vidcontainer.style.display = "block";
        vidcontainer.style.position = "absolute";
        vidcontainer.setAttribute("draggable","true");

        //vidcontainer.style.left = (this.index * 80) + "px";
        //vidcontainer.style.top = (this.index * 60) + "px";
    
        var videoDivPixelHeight = 600;
        var c2cWidth = 320;
        var c2cHeight = 240;
    
        video.srcObject = new MediaStream([ consumer.track ]);
        // let's "yield" and return before playing, rather than awaiting on
        // play() succeeding. play() will not succeed on a producer-paused
        // track until the producer unpauses.
        this.setState({stateMsg: 'starting'});

        video.play()
        .then(()=>{
            //this.sysMessage("Playing cam-2-cam video");
            if(this.state.stateMsg !== 'failed') {
                this.setState({videoPlaying: true});
                this.setState({stateMsg: 'playing'});
            }
        })
        .catch((e) => {
            this.sysMessage("Failed to play cam-2-cam video");
            this.setState({stateMsg: 'failed'});
            this.setState({videoPlaying: false});
            this.sysMessage("Unsubscribing from cam-2-cam video track " + peer.id + " on soup " + peer.soupNum);
            if(peer.soupNum === 2) {
                try {
                    Client.unsubscribeFromTrackSecond(peer.id, "cam-video");
                } catch(e:any) {
                    this.sysMessage("Error unsubscribing from cam-2-cam track " + e.toString());
                    return;
                }
            } else if(peer.soupNum === 3) {
                try {
                    Client.unsubscribeFromTrackThird(peer.id, "cam-video");
                } catch(e:any) {
                    this.sysMessage("Error unsubscribing from cam-2-cam track " + e.toString());
                    return;
                }
            } else {
                try {
                    Client.unsubscribeFromTrack(peer.id, "cam-video");
                } catch(e:any) {
                    this.sysMessage("Error unsubscribing from cam-2-cam track " + e.toString());
                    return;
                }
            }
        });

        video.addEventListener("stalled",this.videoStalled.bind(this));
        video.addEventListener("error",this.videoError.bind(this));

        //this.sysMessage("Setting up cam-2-cam video from " + peer.nickName);
    }

    videoStalled(e:any):void {
        this.sysMessage("stalled event cam-2-cam");
        this.setState({videoPlaying: false});
        this.setState({stateMsg: 'stalled'});
    }

    videoError(e:any):void {
        this.sysMessage("error event cam-2-cam");
        this.setState({videoPlaying: false});
        this.setState({stateMsg: 'error'});
    }

    handleRestart() {
        this.setState({videoPlaying: false});
        this.setState({stateMsg: 'restart'});
        let peer = this.peer;
        let Client = this.Client;
        if(peer) {
            if(peer.soupNum === 2) {
                try {
                    Client.unsubscribeFromTrackSecond(peer.id, "cam-video");
                } catch(e:any) {
                    return;
                }
            } else if(peer.soupNum === 3) {
                try {
                    Client.unsubscribeFromTrackThird(peer.id, "cam-video");
                } catch(e:any) {
                    return;
                }
            } else {
                try {
                    Client.unsubscribeFromTrack(peer.id, "cam-video");
                } catch(e:any) {
                    return;
                }
            }
        }
    }

    handleShrink() {
        let video:HTMLVideoElement = this.videoElement.current!;
        if(this.sizeChangeTrackCounter === 0) {
            video.width = 220;
            video.height = Math.round(video.width * 0.75);
        } else {
            video.width = video.width - 20;
            video.height = Math.round(video.width * 0.75);
        }
        this.sizeChangeTrackCounter--;
        this.setState({videoWidth: video.width});
        this.setState({videoHeight: video.height});
        this.sysMessage("c2c shrink " + this.sizeChangeTrackCounter + " | newwidth: " + video.width);
    }

    handleEnlarge() {
        let video:HTMLVideoElement = this.videoElement.current!;
        if(this.sizeChangeTrackCounter === 0) {
            video.width = 260;
            video.height = Math.round(video.width * 0.75);
        } else {
            video.width = video.width + 20;
            video.height = Math.round(video.width * 0.75);
        }
        this.sizeChangeTrackCounter++;
        this.setState({videoWidth: video.width});
        this.setState({videoHeight: video.height});
        this.sysMessage("c2c enlarge " + this.sizeChangeTrackCounter + " | newwidth: " + video.width);
    }

    handleClose() {
        this.closeCallback(this.state.userPseudo,this.state.streamname,this.state.flashChatSessionID);
    }

    render() {
        return(
            <div draggable={true} onDragStart={this.handleDrag.bind(this)} onDragEnd={this.handleDragEnd.bind(this)} ref={this.videoElementContainer} style={{opacity: this.state.opacity,top: this.state.divTop,left: this.state.divLeft,width: this.state.videoWidth,height: this.state.videoHeight}} className="cam2camVideo">
                <div className="cam2camVidLabel">
                    <span>Cam2Cam {this.state.stateMsg} {this.state.userPseudo}</span>
                    <div className="cam2camVidLabelButtons">
                    <button className="cam2camRestartButton" onClick={this.handleRestart.bind(this)}>&#xE14A;</button><button className="cam2camShrinkButton" onClick={this.handleShrink.bind(this)}>&#xF16E;</button><button onClick={this.handleEnlarge.bind(this)} className="cam2camEnlargeButton">&#xF16D;</button><button onClick={this.handleClose.bind(this)} className="cam2camCloseButton">&#xE8BB;</button>
                    </div>                    
                </div>
                <video ref={this.videoElement} width="240" height="180" controls></video>
            </div>
        )
    }
}

export default Cam2CamVideo;