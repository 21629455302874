import React, { useContext, useEffect } from "react";
import {
  ControlBar,
  PlayerUiContext,
  MediaContainer,
  PlayerAudioButton,
  PlayerBitrateButton,
  PlayerFullscreenButton,
  PlayerGetSoundButton,
  PlayerNewWindowButton,
  PlayerOverlayButton,
  PlayerPlayButton,
  PlayerVideo,
  PlayerVolumeRange,
} from "@video/video-client-web";

const Player = () => {

  const playerCtx = useContext(PlayerUiContext);

  return (
    <MediaContainer>
      <PlayerGetSoundButton />
      <PlayerVideo />
      <ControlBar variant="player">
        <PlayerPlayButton />
        <PlayerAudioButton />
      </ControlBar>
      <PlayerOverlayButton />
    </MediaContainer>
  );
};

export default Player;