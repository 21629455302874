import React, { useState, useCallback, useRef, useEffect,useContext,memo  } from "react";
import styled, { css } from "styled-components";
import { Line,Circle } from "rc-progress";
import Uploady, {
  withRequestPreSendUpdate,
  useItemFinalizeListener,
  withBatchStartUpdate,
  useBatchAddListener,
  useBatchFinalizeListener,
  useBatchProgressListener,
  useItemStartListener,
  useItemProgressListener,
  useItemFinishListener,
  useItemErrorListener,
  useUploady,
  FILE_STATES,
  useAbortItem
} from "@rpldy/uploady";

import { UploadyContext } from '@rpldy/uploady';
import { UploadDropZone } from '@rpldy/upload-drop-zone';
import "./imageUploadStyles.css";
import {ApiHelper } from '../AppContextInterface';
import AppCtx from "../AppContext";
import { Alert, Button, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import UploadButton from "@rpldy/upload-button";

const STATES = {
   PROGRESS: "PROGRESS",
   DONE: "DONE",
   ABORTED: "ABORTED",
   ERROR: "ERROR",
};

const STATE_COLORS = {
   [STATES.PROGRESS]: "#f4e4a4",
   [STATES.DONE]: "#a5f7b3",
   [STATES.ABORTED]: "#f7cdcd",
   [STATES.ERROR]: "#ee4c4c",
};

const StyledCircle = styled(Circle)`
 width: 32px;
 height: 32px;
`;

const ItemProgress = memo(({ id }) => {
    const { completed } = useItemProgressListener(id) || { completed: 0 };
  
    return (
      <StyledCircle
        percent={completed}
        strokeWidth={2}
        trailColor="rgb(175,180,176)"
        strokeColor={{
          "0%": "#ffecb1",
          "100%": "#9eea9e"
        }}
      />
    );
  });
  
  const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
    justify-content: flex-start;
  `;
  
  const ItemName = styled.span`
    display: inline-block;
    margin: 0 10px;
  
    ${({ $aborted }) => ($aborted ? "color: gray;" : undefined)}
    ${({ $success }) => ($success ? "color: green;" : undefined)}
  `;
  
  const UploadListItem = ({ item }) => {
    const [itemState, setState] = useState(item.state);
  
    const abortItem = useAbortItem();
  
    useItemFinalizeListener((item) => {
      setState(item.state);
    }, item.id);
  
    const isAborted = itemState === FILE_STATES.ABORTED,
      isSuccess = itemState === FILE_STATES.FINISHED,
      isFinished = ![FILE_STATES.PENDING, FILE_STATES.UPLOADING].includes(
        itemState
      );
  
    const onAbortItem = () => {
      abortItem(item.id);
    };
  
    return (
      <ItemWrapper>
        {!isFinished && <ItemProgress id={item.id} />}
        {isAborted && <span><FontAwesomeIcon icon={solid("circle-xmark")} /></span>}
        {isSuccess && <span><FontAwesomeIcon icon={solid("circle-check")} /></span>}
        <ItemName $aborted={isAborted} $success={isSuccess}>
          {item.file.name}
        </ItemName>
        {!isFinished && <span onClick={onAbortItem}><FontAwesomeIcon icon={solid("trash")} /></span>}
      </ItemWrapper>
    );
  };

  const UploadProgress = (props) => {
    const [progress, setProgess] = useState(0);
    const progressData = useItemProgressListener();
  
    if (progressData && progressData.completed > progress) {
      setProgess(() => progressData.completed);
    }

    const uploadyContext = useContext(UploadyContext);

    useItemFinishListener((item) => {
        console.log("item finish: " + JSON.stringify(item.uploadResponse));
        if(typeof(item.uploadResponse) !== "undefined") {
            try {
                const myResponseData = JSON.parse(item.uploadResponse);
                if(typeof(myResponseData.message) !== "undefined") {
                    props.finishCallback(item.uploadResponse.message);
                } else {
                    props.finishCallback("Upload done");
                }
            } catch(e) {
                props.finishCallback("Upload completed");
            }
        }
    });
  
    useItemErrorListener((item) => {
      console.log(`item ${item.id} upload error !!!! `, item);
    });
  
    useItemStartListener((item) => {
      console.log(`item ${item.id} starting to upload,name = ${item.file.name}`);
    });

    return (
        progressData && (
            <div>
                <div style={{position: "absolute",zIndex: 1,color: "#fff",textAlign: "center",width: "420px",paddingTop: "2px"}}>{progress}%</div>
                <Line
                    style={{ height: "20px", marginTop: "0px",position: "relative" }}
                    strokeWidth={5}
                    strokeColor={progress === 100 ? "#00a626" : "#2db7f5"}
                    percent={progress}
                />
            </div>
        )
    );
};
  
  const ListContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20px;
  `;
  
  const UploadList = () => {
    const { processPending } = useUploady();
    const [items, setItems] = useState([]);
  
    useBatchAddListener((batch) => {
      setItems((items) => items.concat(batch.items));
    });
  
    return (
      <ListContainer>
        {items.length > 0 ? <button onClick={processPending} className='btn btn-primary btn-lg'>Start Upload</button> : null}
        {items.map((item) => (
          <UploadListItem key={item.id} item={item} />
        ))}
      </ListContainer>
    );
  };
  
  export function ImageUploadNoCrop(props) {
    const previewMethodsRef = useRef();
    const [producerID, setProducerID] = useState(props.producerID);
    const [uploadInfo, setUploadInfo] = useState(props.uploadInfo);
    const myContext = useContext(AppCtx);
    console.log("in Imageupload: " + myContext.language);
    const handleFileInput = (e) => {
      console.log("File selected for imageupload: " + e.target.files[0].name);
    }
  
    const getText = (msg) => {
      if(myContext.language === "de") {
        if(msg === "Select Image to upload") {
          return "Klicke hier um Bilder zum Hochladen auszuwählen";
        }
        if(msg === "Cancel") {
          return "Abbrechen";
        }
      } else {
        if(msg === "Select Image to upload") {
          return "Click here in order to select to upload (supports cropping)";
        }
      }
  
      return msg;
    }
  
    return (
      <Uploady
        accept=".png, .jpg, .jpeg"
        multiple={true}
        autoUpload={false}
        destination={{ url: ApiHelper.getAPIUrl(myContext) + "/upload?producerID=" + encodeURIComponent(producerID) + "&uploadInfo=" + encodeURIComponent(uploadInfo) }}
      >
        <div className="ImageUpload">
          <UploadButton grouped maxGroupSize={3} className="btn btn-info btn-sm p-3" style={{marginTop: "10px"}}>{getText("Select Image to upload")}</UploadButton>
          <UploadProgress finishCallback={props.finishCallback} />
          <UploadList />
        </div>
      </Uploady>
    );
  }