import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import AppContext from './AppContext';

class FeatureRequest extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            userPseudo: props.userPseudo,
            feature: props.feature,
            guid: props.guid,
            featureHumanString: this.getHumanStringForFeature(props.feature)
        };

        this.acceptCallback = props.acceptCallback;
        this.denyCallback = props.denyCallback;
    }

    handleAccept() {
        this.acceptCallback(this.state.feature,this.state.userPseudo,this.state.guid);
    }

    handleDeny() {
        this.denyCallback(this.state.feature,this.state.userPseudo,this.state.guid);
    }

    getHumanStringForFeature(feature) {
        if(feature === "sound") {
            return "Sound";
        } else if(feature === "dildo") {
            return "Dildo-Control";
        } else if(feature === "multiPrivate") {
            return "Premium-Show";
        } else if(feature === "privateSession") {
            return "Separee";
        } else if(feature === "cam2cam") {
            return "Cam-2-Cam";
        } else if(feature === "userMic") {
            return "UserMic";
        }else if(feature === "lovense") {
            return "Lovense / Kiiroo";
        }
    
        return feature;
    }

    getText(exp) {
        if(this.context.language === "de") {
            if(exp === "Accept") {
                return "Annehmen";
            }
            if(exp === "Deny") {
                return "Ablehnen";
            }
        } else {
            if(exp === "Accept") {
                return "Accept";
            }
            if(exp === "Deny") {
                return "Deny";
            }
        }
    }

    render() {
        return(
            <Alert variant='info' dismissible onClose={this.handleDeny.bind(this)}>
                <p><b>{this.state.userPseudo} fragt nach {this.state.featureHumanString}</b></p>
                <Row>
                    <Col>
                        <Button variant="success" size="sm" onClick={this.handleAccept.bind(this)}>{this.getText("Accept")} (Enter/F1)</Button>
                    </Col>
                    <Col>
                        <Button variant="danger" size="sm" onClick={this.handleDeny.bind(this)}>{this.getText("Deny")} (F2)</Button>
                    </Col>
                </Row>
            </Alert>
        )
    }
}

export default FeatureRequest;