import React from 'react';
import AppContext from './AppContext';
import axios from 'axios';
import {
    CallContext,
    PlayerUiContext,
    PlayerUiState,
    VideoClient,
    types,
  } from "@video/video-client-web";

  import {
    ControlBar,
    MediaContainer,
    PlayerAudioButton,
    PlayerBitrateButton,
    PlayerFullscreenButton,
    PlayerGetSoundButton,
    PlayerOverlayButton,
    PlayerPlayButton,
    PlayerVideo,
    PlayerVolumeRange,
  } from "@video/video-client-web";

interface UserMicAudio {
    state:UserMicAudioState;
    props:UserMicAudioProps;
    closeCallback(userPseudo:string,streamname:string,flashChatSessionID:string):void;
    checkCounter:number;
    sizeChangeTrackCounter:number;
    dragStartX:number;
    dragStartY:number;
    cam2CamPollIntervalID:number;
    audioElementContainer:any;
    sysMessage(msg:string):void;
    index:number;
    checkUserMicTimerEnabled:boolean;
    checkUserMicTimer:NodeJS.Timer;
}

interface UserMicAudioState {
    userPseudo:string;
    callId:string;
    stateMsg:string;
    flashChatSessionID:string;
    vc:types.VideoClient | null;
    playerUI:PlayerUiState | null;
    manifestCheckState:string;
    manifestCheckCount:number;
    manifestFailCount:number;
    divTop:number;
    divLeft:number;
    opacity:string;
}

interface UserMicAudioProps {
    userPseudo:string,
    callId:string,
    flashChatSessionID:string,
    closeCallback(userPseudo:string,streamname:string,flashChatSessionID:string):void,
    sysMessage(msg:string):void,
    index:number,
    playerUI:PlayerUiState | null;
}

class UserMicAudio extends React.Component {
    static contextType = AppContext;

    constructor(props:UserMicAudioProps) {
        super(props);

        this.state = {
            userPseudo: props.userPseudo,
            callId: props.callId,
            stateMsg: '',
            flashChatSessionID: props.flashChatSessionID,
            vc:null,
            playerUI:props.playerUI,
            manifestCheckState:"",
            manifestCheckCount:0,
            manifestFailCount:0,
            divTop: 110 + (props.index * 20),
            divLeft: 10 + (props.index * 20),
            opacity: "1.0"
        };

        this.closeCallback = props.closeCallback;
        this.checkCounter = 0;
        this.sizeChangeTrackCounter = 0;

        this.dragStartX = 0;
        this.dragStartY = 0;
        this.cam2CamPollIntervalID = 0;
        this.audioElementContainer = React.createRef();
        this.sysMessage = props.sysMessage.bind(this);
        this.index = props.index;
    }

    componentDidMount() {
        if(this.state.callId !== "" && this.state.playerUI === null) {
            this.fetchToken(this.state.callId,this.state.userPseudo);
        } else {
            if(!this.checkUserMicTimerEnabled) {
                this.checkUserMicTimer = setInterval(this.checkUserMicStatus.bind(this), 250);
                this.checkUserMicTimerEnabled = true;
            }
        }
    }

    componentWillUnmount() {
        if(this.checkUserMicTimerEnabled) {
            clearInterval(this.checkUserMicTimer as any);
        }
        if (this.state.playerUI != null) {
            //this.state.playerUI.dispose();
            this.setState({playerUI:null});
        }
        if (this.state.vc != null) {
            this.state.vc.dispose();
            this.setState({vc:null});
        }
    }

    handleDrag(ev: React.DragEvent<HTMLDivElement>):void {
        const target = (ev.target as HTMLDivElement);
        this.dragStartX = ev.pageX - target.offsetLeft;
        this.dragStartY = ev.pageY - target.offsetTop;
        this.setState({opacity: "0.4"});
    }

    handleDragEnd(ev: React.DragEvent<HTMLDivElement>):void {
        this.setState({divLeft: ev.pageX - this.dragStartX});
        this.setState({divTop: ev.pageY - this.dragStartY});
        this.setState({opacity: "1.0"});
    }

    handleClose() {
        this.closeCallback(this.state.userPseudo,this.state.callId,this.state.flashChatSessionID);
    }

    buildManifestUrl(callId:string,token:string) {
        let manifestUrl = "https://guppy-prod-euw1d-manifest2.generflow.com/live/" + callId + ".json?accessToken=" + encodeURIComponent(token) + "&time=" + encodeURIComponent(new Date().getMilliseconds());
        this.setState({manifestUrl:manifestUrl});

        if (this.state.vc == null) {
            const yourDomain:string = "https://guppy-prod-euw1d.generflow.com";
            //const axios = require('axios').default;
            let callId:string = this.state.callId;
            let pseudo = this.state.userPseudo;
            
            const opts: types.VideoClientOptions = {
              userId:"",
              backendEndpoints: [yourDomain],
              token: this.fetchTokenRefresh.bind(this),
            };
            const newVc = new VideoClient(opts);
            this.setState({vc: newVc});
            this.setState({stateMsg:"Created VideoClient"});

            const options:Partial<types.PlayerOptions> = {
                autoPlay: true,
                volume: 1.0,
                muted: false
            };

            const player = newVc.requestPlayer(manifestUrl, options);
            this.setState({stateMsg:"Created Player"});

            //player.forcedMute = !this.state.soundActive;

            this.setState({playerUI: new PlayerUiState(player)});

            if(!this.checkUserMicTimerEnabled) {
                this.checkUserMicTimer = setInterval(this.checkUserMicStatus.bind(this), 250);
                this.checkUserMicTimerEnabled = true;
            }
        }
    }

    fetchTokenRefresh = async():Promise<string> => {
        let tokenURL:string;

        tokenURL = "https://streamauth.guppy.live/FetchPlayerToken.aspx?SessionID=" + encodeURIComponent(this.state.callId) + "&Pseudo=" + encodeURIComponent(this.state.userPseudo);

        try {
            const response = await fetch(tokenURL);
            const tokenResponse = await response.json();
            return tokenResponse.token;
        } catch (error) {
            console.error('UserMicAudio fetchTokenRefresh Error:', error);
        }

        return "";
    }

    checkUserMicStatus() {
        if(this.props.playerUI !== null && this.state.playerUI === null) {
            this.setState({playerUI:this.props.playerUI});
        }

        if(this.state.playerUI !== null) {
            let myPlayer:PlayerUiState = this.state.playerUI as PlayerUiState;

            if(myPlayer.videoElement) {
                if(myPlayer.videoElement.current) {
                    this.setState({stateMsg: "Time " + myPlayer.videoElement.current.currentTime});
                } else {
                    this.setState({stateMsg: "No VideoElement"});
                }
            } else {
                this.setState({stateMsg: "No VideoElement"});
            }
        } else {
            this.setState({stateMsg: "No PlayerUI"});
        }
    }

    refreshToken():Promise<string> {
        let callId:string = this.state.callId;
        let pseudo = this.state.userPseudo;
        //const axios = require('axios').default;
        let url:string;

        url = "https://streamauth.guppy.live/FetchPlayerToken.aspx?SessionID=" + encodeURIComponent(callId) + "&Pseudo=" + encodeURIComponent(pseudo);

        if(typeof(console) != "undefined") {
            console.log("guppyplayer -> refreshTokenURL: '" + url + "'");
        }
        return axios.get(url);
    }

    fetchToken = async(callId:string,pseudo:string) => {
        //const axios = require('axios').default;
        let url:string;

        this.setState({manifestCheckState: "Fetching Token"});

        this.setState({stateMsg: "Fetching Token"});

        url = "https://streamauth.guppy.live/FetchPlayerToken.aspx?SessionID=" + encodeURIComponent(callId) + "&Pseudo=" + encodeURIComponent(pseudo);

        try {
            if(typeof(console) != "undefined") {
                console.log("guppyplayer -> fetchTokenURL: '" + url + "'");
            }
            const response = await axios.get(url);
            if(typeof(console) != "undefined") {
                console.log("guppyplayer -> token: '" + response.data.token + "'");
            }
            
            this.setState({stateMsg: "Initializing Player"});
            this.buildManifestUrl(callId,response.data.token);
        } catch (error) {
            console.error('ERROR:');
            console.error(error);
            this.setState({stateMsg: "Token Error"});
            this.setState({manifestCheckState: "Token Error"});
            this.setState({manifestCheckCount: this.state.manifestFailCount + 1});
            if(this.state.manifestFailCount <= 6) {
                this.setState({manifestUrl: ""});
                //setTimeout(this.checkBroadcast.bind(this),4000);
            } else {
                this.setState({stateMsg: "Token Failed"});
                this.setState({manifestCheckState: "Token Error Permanent"});
            }
        }
    }

    render() {
        return(
            <div draggable={true} onDragStart={this.handleDrag.bind(this)} onDragEnd={this.handleDragEnd.bind(this)} ref={this.audioElementContainer} style={{opacity: this.state.opacity,top: this.state.divTop,left: this.state.divLeft}} className="userMicAudio">
                <div className="userMicAudioLabel">
                    <span>UserMic {this.state.stateMsg} {this.state.userPseudo}</span>
                    <div className="userMicAudioLabelButtons">
                        <button onClick={this.handleClose.bind(this)} className="userMicCloseButton">&#xE8BB;</button>
                    </div>
                </div>
                { this.state.playerUI !== null ? 
                <PlayerUiContext.Provider value={this.state.playerUI}>
                    <MediaContainer>
                        <ControlBar variant="player">
                            <PlayerVolumeRange></PlayerVolumeRange>
                        </ControlBar>
                    </MediaContainer>
                </PlayerUiContext.Provider> : null }
            </div>
        )
    }
}

export default UserMicAudio;