import React, { FormEvent } from 'react';
import AppContext from './AppContext';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faBluetooth, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { ButtonGroup } from 'react-bootstrap';
import QRCode from 'qrcode.react';
const API_BASE = 'https://api.feel-app.com/api/v1';
export interface kiirooModalProps {
    closeCallback: () => void;
    language: string;
    producerID: string;
    pseudo: string;
    producertoken: string;
    onStatusUpdate: (status: boolean) => void; 

}

interface kiirooModal {
    state: kiirooModalState;
    props: kiirooModalProps;
    closeCallback: () => void;
}

interface TipRequest {
    sender: string;
    amount: string;
    duration: number;
    strength: number;
    customText: string;
}
interface kiirooModalState {
    language: string;
    producerID: string;
    pseudo: string;
    initializing: boolean;
    fetching: boolean;
    kiirooAppConnected: boolean;
    kiirooDeviceConnected: boolean;
    kiirooqrcode: string;
    producertoken: string;
}
interface KiirooModalProps {
    setProducerToken: React.Dispatch<React.SetStateAction<string>>;
    producertoken: string;
}

declare global {
    interface Window {
        initializekiiroo(authToken: string, uid: string): void;
    }
}

class kiirooModal extends React.Component<kiirooModalProps, kiirooModalState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;
    intervalId: NodeJS.Timeout | null = null;

    constructor(props: kiirooModalProps) {
        super(props);
        this.closeCallback = props.closeCallback;

        this.state = {
            kiirooqrcode: "",
            language: props.language,
            producerID: props.producerID,
            pseudo: props.pseudo,
            initializing: true,
            fetching: false,
            kiirooAppConnected: false,
            producertoken: props.producertoken,
            kiirooDeviceConnected: false
        };
    }

    componentDidMount() {
        this.loadingQrCode();
        this.intervalId = setInterval(this.checkOnlineStatus, 5000); // Check every 5 seconds
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    async loadingQrCode() {
        console.log("Loading QR Code with token: ", this.context.loginData!);
        try {
            const response = await axios.get(`https://guppy.site/kiirooproxy?method=auth&endPoint=auth&producerID=${this.context.loginData!.producerID}&partner_token=${this.state.producertoken}`);
            if (response.data && response.data.auth_token) {
                this.setState({ 
                    kiirooqrcode: response.data.auth_token,
                });
                this.checkOnlineStatus();
                console.log(this.state.kiirooAppConnected);
            } else {
                console.log("Error in loading QR Code: ", response.data);
            }
        } catch (error) {
            console.error("Error in QR Code loading: ", error);
        }
    }

    checkOnlineStatus = async () => {
        try {
            const response = await axios.get(`https://guppy.site/kiirooproxy?method=get&endPoint=status&producerID=${this.context.loginData!.producerID}&partner_token=${this.state.producertoken}`);
            console.log(response);
            if (response.data && response.data.online) {
                this.setState({ kiirooAppConnected: response.data.online });
                console.log("kiirooAppConnected:" + this.state.kiirooAppConnected);

                if (response.data.devices.length > 0){
                    this.props.onStatusUpdate(response.data.online); 
                    this.setState({ kiirooDeviceConnected: true });
                    console.log("kiirooDeviceConnected:" + this.state.kiirooDeviceConnected);
                    console.log("Länge der DevicesArray: " + response.data.devices.length);
                } else {
                    this.props.onStatusUpdate(false); 
                    this.setState({ kiirooDeviceConnected: false });
                    console.log("kiirooDeviceConnected:" + this.state.kiirooDeviceConnected);
                }
    
            }
            else {
                console.log(response.data);
                this.setState({ kiirooAppConnected: false });
                this.props.onStatusUpdate(false); 
                this.setState({ kiirooDeviceConnected: false });
            }
        } catch (error) {
            console.error("Error checking online status: ", error);
        }
    }
    
    getText(exp: string) {
        if (this.state.language === "de") {
            if (exp === "ConnectkiirooSmartphone") {
                return "Gerät nicht gefunden: Bitte öffnen Sie die App. Verbinden Sie Ihre Kiiroo-Toys mit der iOS- oder Android-App, indem du den QR-Code in der FeelConnect-App auf deinem Smartphone scannst. Dieser Schritt ist nur einmal erforderlich.";
            }
            if (exp === "alreadyConnected") {
                return "Dein Toy ist nun erfolgreich gekoppelt!";
            }
            if (exp === "devicenotfound") {
                return "Das Toy wurde nicht in der FeelConnect app gefunden. Bitte füge es hinzu!";
            }
            return exp;
        } else {
            if (exp === "ConnectkiirooSmartphone") {
                return "Device not found: Please open the app. You can connect your Kiiroo toys using the iOS or Android app by scanning the QR code in the FeelConnect app on your smartphone. This step is only required once.";
            }
            if (exp === "alreadyConnected") {
                return "Your toy is now successfully connected!";
            }
            return exp;
        }
    }
    
    sendTip = async (tipData: TipRequest) => {
        const API_BASE = 'https://api.feel-app.com/api/v1';
        try {
            const response = await axios.post(`${API_BASE}/user/${this.state.producerID}/tip?partner_token=${this.state.producertoken}`, tipData);
            console.log("Tip sent, server response:", response.data);
            alert(`Tip sent, server response: ${response.data}`);
        } catch (error) {
            console.error("Failed to send tip:", error);
        }
    }
    
    render() {
        return (
            <Modal show={true} onHide={this.closeCallback.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>QR Code Authentication</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.kiirooAppConnected === false  ? (
                        <p>{this.getText("ConnectkiirooSmartphone")}</p>
                    ) : (
                        this.state.kiirooDeviceConnected === false? (
                            <p>{this.getText("devicenotfound")}</p>
                        ) : (
                            <></>
                        )
                    )}
                      
                    <div id="kiirooQrCode" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {this.state.kiirooAppConnected === false && this.state.kiirooDeviceConnected === false? (
                            this.state.kiirooqrcode ? (
                                <img src={`http://api.qrserver.com/v1/create-qr-code/?data=${this.state.kiirooqrcode}`} alt="QR Code" />
                            ) : (
                                <span>Loading QR Code...</span>
                            )
                        ) : (
                            this.state.kiirooDeviceConnected === true ?(
                                <p>{this.getText("alreadyConnected")}</p>
                            ):(
                                <p>{this.getText("devicenotfound")}</p>
                            )
                            
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={this.closeCallback.bind(this)}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    
        
}

export default kiirooModal;