import Button from 'react-bootstrap/Button';
import { ListGroup, ModalBody } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

const DildoPortSelect = (props) => {
    //const [dildoPorts, setDildoPorts] = React.useState(false);
    
    const selectDildoPort = (comName) => {
        props.selectDildoPort(comName);
    }
    return(
        <Modal show={true} onHide={props.closeCallback} className='ToyOverlay'>
        <Modal.Header closeButton >
          <Modal.Title>Dildo-Control</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup className='mt-5'>
            {props.dildoPorts.map((port,index) => 
              <ListGroup.Item key={index}>
                {port.friendlyName} 
                <Button onClick={selectDildoPort.bind(this, port.path)} variant="secondary" size="sm">Select</Button>
              </ListGroup.Item>)}
          </ListGroup>
        </Modal.Body>
      </Modal>
      
        
    )
}

export default DildoPortSelect;