import React from 'react';
import AppContext from './AppContext';
import { Device } from 'mediasoup-client';
import { types } from 'mediasoup-client';
import SignalingInterface, { SyncResponse } from './SignalingInterface'; // Our own signaling stuff.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';

interface Cam2CamVideoSoup {
    state:Cam2CamVideoSoupState;
    props:Cam2CamVideoSoupProps;
    closeCallback(userPseudo:string,streamname:string,flashChatSessionID:string):void;
    checkCounter:number;
    sizeChangeTrackCounter:number;
    dragStartX:number;
    dragStartY:number;
    videoElement:React.RefObject<HTMLVideoElement>;
    videoElementContainer:React.RefObject<HTMLDivElement>;
    sysMessage(msg:string):void;
    index:number;
    checkCam2CamStatusTimerEnabled:boolean;
    checkCam2CamStatusTimer:NodeJS.Timer;
    lastPlayTime:number;
    mySignaling: SignalingInterface;
    device: Device;
    syncTimer:NodeJS.Timer;
    recvTransport: types.Transport;
    videoConsumer: types.Consumer;
    myPeerId: string;
}

interface Cam2CamVideoSoupState {
    userPseudo:string,
    stateMsg:string,
    debugmsg:string,
    flashChatSessionID:string,
    streamFound:boolean,
    videoPlaying:boolean,
    orderIndex:number,
    divTop:number,
    divLeft:number,
    videoWidth:number,
    videoHeight:number,
    opacity:string,
    initialized:boolean,
    peerId:string,
    soupServer:string,
    mobileCam:boolean
}

interface Cam2CamVideoSoupProps {
    userPseudo:string,
    flashChatSessionID:string,
    peerId:string,
    soupServer:string,
    closeCallback(userPseudo:string,streamname:string,flashChatSessionID:string):void,
    sysMessage(msg:string):void,
    index:number,
    orderIndex:number
}

class Cam2CamVideoSoup extends React.Component {
  static contextType = AppContext;

  constructor(props: Cam2CamVideoSoupProps) {
    super(props);

    this.state = {
      userPseudo: props.userPseudo,
      stateMsg: "",
      flashChatSessionID: props.flashChatSessionID,
      streamFound: false,
      videoPlaying: false,
      peerId: props.peerId,
      soupServer: props.soupServer,
      orderIndex: props.orderIndex,
      divTop: 180 + props.orderIndex * 20,
      divLeft: 10 + props.orderIndex * 20,
      videoWidth: 240,
      videoHeight: 180,
      opacity: "1.0",
      initialized: false,
      mobileCam: false,
      debugmsg: "",
    };

    library.add(faPlay);

    this.closeCallback = props.closeCallback;
    this.checkCounter = 0;
    this.sizeChangeTrackCounter = 0;

    this.dragStartX = 0;
    this.dragStartY = 0;
    this.videoElement = React.createRef();
    this.videoElementContainer = React.createRef();
    this.lastPlayTime = 0.0;
    this.sysMessage = props.sysMessage.bind(this);
    this.index = props.index;
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    if (this.checkCam2CamStatusTimerEnabled) {
      clearInterval(this.checkCam2CamStatusTimer as any);
      this.checkCam2CamStatusTimerEnabled = false;
    }

    clearInterval(this.syncTimer as any);

    try {
      if (this.videoConsumer) {
        this.videoConsumer.close();
        this.mySignaling.request("close-consumer", this.videoConsumer.id);
      }
    } catch (e: any) {
      console.log("Error closing videoConsumer: " + e);
    }

    this.cleanupSoup();
  }

  cleanupSoup = async () => {
    try {
      if (this.recvTransport) {
        this.recvTransport.close();
      }
    } catch (e: any) {
      console.log("Error closing recvTransport: " + e);
    }

    try {
      await this.mySignaling.request("leave");
    } catch (e: any) {
      console.log("Error leaving as soup peer: " + e);
    }
  };

  sync = async () => {
    if (this.state.initialized) {
      const mySyncResponse: SyncResponse = await this.mySignaling.requestSync();
      if (mySyncResponse) {
        if (mySyncResponse.error) {
          this.setState({
            debugmessage: console.error(mySyncResponse.error.toString()),
          });
        } else {
          this.setState({ peers: mySyncResponse.peers });
        }
      }
    }
  };

  uuidv4(): string {
    return "111-111-1111".replace(/[018]/g, () =>
      (crypto.getRandomValues(new Uint8Array(1))[0] & 15).toString(16)
    );
  }

  init = async () => {
    this.setState({ encoderDevicesSelectable: true });
    this.setState({ initializing: true });

    this.myPeerId = this.uuidv4();

    this.mySignaling = new SignalingInterface(
      this.myPeerId,
      "https://soup01.guppy.live:3000"
    );

    // Create a device (use browser auto-detection).
    this.device = new Device();

    // Communicate with our server app to retrieve router RTP capabilities.
    let { routerRtpCapabilities } = await this.mySignaling.request(
      "join-as-new-peer"
    );

    // Load the device with the router RTP capabilities.
    await this.device.load({ routerRtpCapabilities });

    // Check whether we can produce video to the router.
    if (this.device.loaded) {
      this.setState({ debugmsg: "device loaded" });
    } else {
      console.warn("device not loaded");
      this.setState({ debugmsg: "device not loaded" });
      // Abort next steps.
      return;
    }

    this.setState({ initialized: true });
    this.syncTimer = setInterval(this.sync.bind(this), 1000);

    const { transportOptions } = await this.mySignaling.request(
      "create-transport",
      { direction: "recv" }
    );

    console.log(transportOptions);
    this.setState({
      debugmsg: "created transport on server: " + transportOptions.id,
    });

    // Create the local representation of our server-side transport.
    this.recvTransport = this.device.createRecvTransport(transportOptions);

    // Set transport "connect" event handler.
    this.recvTransport.on("connect", this.onRecvTransportConnect.bind(this));
    this.recvTransport.on(
      "connectionstatechange",
      this.onRecvTransportConnectionStateChange.bind(this)
    );

    this.setState({ debugmsg: "trying to consume video" });

    // this.state.peerId is the target peerId of the user we want to consume video from
    let { producerId, id, kind, rtpParameters, type, producerPaused, error } =
      await this.mySignaling.request("recv-track", {
        peerId: this.myPeerId,
        mediaTag: "cam-video",
        mediaPeerId: this.state.peerId,
        rtpCapabilities: this.device.rtpCapabilities,
      });

    if (typeof error != "undefined") {
      if (error) {
        if (error !== "") {
          console.error(
            "Error receiving cam-2-cam consumer from soupserver: " + error
          );
          this.setState({
            debugmsg:
              "Error receiving cam-2-cam consumer from soupserver: " + error,
          });
          this.setState({ stateMsg: "Consume Error" });
          return;
        }
      }
    }

    this.setState({ debugmsg: "got consumer params" });

    console.log("received consumerParameters - id:" + id);
    console.log("received consumerParameters - producerId:" + producerId);
    console.log("received consumerParameters - kind:" + kind);
    console.log("received consumerParameters - type:" + type);
    console.log(
      "received consumerParameters - producerPaused:" + producerPaused
    );
    console.log(
      "received consumerParameters: " + JSON.stringify(rtpParameters)
    );

    try {
      this.videoConsumer = await this.recvTransport.consume({
        id: id,
        producerId: producerId,
        rtpParameters: rtpParameters,
        kind: kind,
        appData: { mediaTag: "cam-video", mediaPeerId: this.state.peerId },
      });
      this.mySignaling.request("resume-consumer", { consumerId: id });
      this.playVideoTrack(this.videoConsumer.track);
    } catch (e: any) {
      this.setState({
        debugmsg:
          "ConsumeException: " +
          e.toString() +
          "|" +
          producerId +
          "|" +
          id +
          "|" +
          kind +
          "|" +
          type,
      });
      this.setState({ stateMsg: "Consume Exception" });
    }
  };

  onRecvTransportConnect = async (
    { dtlsParameters }: any,
    callback: () => void,
    errback: (error: Error) => void
  ) => {
    this.setState({ debugmsg: "recvTransport connected" });

    // Here we must communicate our local parameters to our remote transport.
    try {
      await this.mySignaling.request("connect-transport", {
        transportId: this.recvTransport.id,
        dtlsParameters,
      });

      // Done in the server, tell our transport.
      callback();
    } catch (error) {
      errback(new Error("Eine Fehlermeldung hier"));
    }
  };

  onRecvTransportConnectionStateChange = async (state: string) => {
    this.setState({ connstate: state });
    if (state === "closed" || state === "failed" || state === "disconnected") {
      this.setState({ debugmsg: "recvTransport closed" });
    }
  };

  handleDrag(ev: React.DragEvent<HTMLDivElement>) {
    let myTarget: HTMLDivElement = ev.target as HTMLDivElement;
    if (myTarget !== null) {
      this.dragStartX = ev.pageX - myTarget.offsetLeft;
      this.dragStartY = ev.pageY - myTarget.offsetTop;
    }
    this.setState({ opacity: "0.4" });
  }

  handleDragEnd(ev: React.DragEvent<HTMLDivElement>) {
    this.setState({ divLeft: ev.pageX - this.dragStartX });
    this.setState({ divTop: ev.pageY - this.dragStartY });
    this.setState({ opacity: "1.0" });
  }

  sleep = async (ms: number) => {
    return new Promise<void>((r) => setTimeout(() => r(), ms));
  };

  playVideoTrack = async (videoTrack: MediaStreamTrack) => {
    let video: HTMLVideoElement | null = this.videoElement.current;

    if (!video) {
      return;
    }

    video.muted = true;

    var vidcontainer: HTMLDivElement = this.videoElementContainer.current!;
    vidcontainer.style.display = "block";
    vidcontainer.style.position = "absolute";
    vidcontainer.setAttribute("draggable", "true");

    video.srcObject = new MediaStream([videoTrack]);
    // let's "yield" and return before playing, rather than awaiting on
    // play() succeeding. play() will not succeed on a producer-paused
    // track until the producer unpauses.
    this.setState({ stateMsg: "starting" });

    video
      .play()
      .then(() => {
        //this.sysMessage("Playing cam-2-cam video");
        if (this.state.stateMsg !== "failed") {
          this.setState({ videoPlaying: true });
          this.setState({ stateMsg: "playing" });
          if (!this.checkCam2CamStatusTimerEnabled) {
            this.checkCam2CamStatusTimer = setInterval(
              this.checkCam2CamStatus.bind(this),
              1000
            );
            this.checkCam2CamStatusTimerEnabled = true;
          }
        }
      })
      .catch((e) => {
        this.sysMessage("Failed to play cam-2-cam video");
        this.setState({ stateMsg: "failed" });
        this.setState({ videoPlaying: false });
        this.sysMessage("Unsubscribing from cam-2-cam video track");
      });

    video.addEventListener("stalled", this.videoStalled.bind(this));
    video.addEventListener("error", this.videoError.bind(this));
  };

  videoStalled(e: any): void {
    this.sysMessage("stalled event cam-2-cam");
    this.setState({ videoPlaying: false });
    this.setState({ stateMsg: "stalled" });
  }

  videoError(e: any): void {
    this.sysMessage("error event cam-2-cam");
    this.setState({ videoPlaying: false });
    this.setState({ stateMsg: "error" });
  }

  handleShrink() {
    let videoWidth: number = 0;
    let videoHeight: number = 0;
    if (this.sizeChangeTrackCounter === 0) {
      videoWidth = 220;
      if (this.state.mobileCam) {
        videoHeight = Math.round(videoWidth * 1.33);
      } else {
        videoHeight = Math.round(videoWidth * 0.75);
      }
    } else {
      videoWidth = this.state.videoWidth - 20;
      if (this.state.mobileCam) {
        videoHeight = Math.round(videoWidth * 1.33);
      } else {
        videoHeight = Math.round(videoWidth * 0.75);
      }
    }
    this.sizeChangeTrackCounter--;
    this.setState({ videoWidth: videoWidth });
    this.setState({ videoHeight: videoHeight });
    this.sysMessage(
      "c2c shrink " + this.sizeChangeTrackCounter + " | newwidth: " + videoWidth
    );
  }

  handleEnlarge() {
    let videoWidth: number = 0;
    let videoHeight: number = 0;
    if (this.sizeChangeTrackCounter === 0) {
      videoWidth = 260;
      if (this.state.mobileCam) {
        videoHeight = Math.round(videoWidth * 1.33);
      } else {
        videoHeight = Math.round(videoWidth * 0.75);
      }
    } else {
      videoWidth = this.state.videoWidth + 20;
      if (this.state.mobileCam) {
        videoHeight = Math.round(videoWidth * 1.33);
      } else {
        videoHeight = Math.round(videoWidth * 0.75);
      }
    }
    this.sizeChangeTrackCounter++;
    this.setState({ videoWidth: videoWidth });
    this.setState({ videoHeight: videoHeight });
    this.sysMessage(
      "c2c enlarge " +
        this.sizeChangeTrackCounter +
        " | newwidth: " +
        videoWidth
    );
  }

  checkCam2CamStatus() {
    if (this.videoElement !== null) {
      if (this.videoElement.current) {
        this.videoElement.current.muted = true;
        if (this.videoElement.current.style) {
          this.videoElement.current.style.objectFit = "contain";
        }

        let videoWidth: number = 0;
        let videoHeight: number = 0;

        if (
          this.videoElement.current.videoHeight >
          this.videoElement.current.videoWidth
        ) {
          if (!this.state.mobileCam) {
            videoWidth = this.state.videoWidth;
            videoHeight = Math.round(videoWidth * 1.33);
            this.setState({ videoWidth: videoWidth });
            this.setState({ videoHeight: videoHeight });
          }
          this.setState({ mobileCam: true });
        } else {
          if (this.state.mobileCam) {
            videoWidth = this.state.videoWidth;
            videoHeight = Math.round(videoWidth * 0.75);
            this.setState({ videoWidth: videoWidth });
            this.setState({ videoHeight: videoHeight });
          }
          this.setState({ mobileCam: false });
        }
        this.setState({
          stateMsg:
            "Playing " +
            this.videoElement.current.videoWidth +
            "x" +
            this.videoElement.current.videoHeight,
        });
      } else {
        this.setState({ stateMsg: "No VideoElement" });
      }
    } else {
      this.setState({ stateMsg: "No PlayerUI" });
    }
  }

  handleClose() {
    this.closeCallback(
      this.state.userPseudo,
      this.state.peerId,
      this.state.flashChatSessionID
    );
  }

  render() {
    return (
      <div
        draggable={true}
        onDragStart={this.handleDrag.bind(this)}
        onDragEnd={this.handleDragEnd.bind(this)}
        ref={this.videoElementContainer}
        style={{
          opacity: this.state.opacity,
          top: this.state.divTop,
          left: this.state.divLeft,
          width: this.state.videoWidth,
          height: this.state.videoHeight,
        }}
        className="Cam2CamVideoSoup"
      >
        <div className="cam2camVidLabel">
          <span>
            Cam2Cam {this.state.stateMsg} {this.state.userPseudo}
          </span>
          <div className="cam2camVidLabelButtons">
            <button
              className="cam2camShrinkButton"
              onClick={this.handleShrink.bind(this)}
            >
              &#xF16E;
            </button>
            <button
              onClick={this.handleEnlarge.bind(this)}
              className="cam2camEnlargeButton"
            >
              &#xF16D;
            </button>
            <button
              onClick={this.handleClose.bind(this)}
              className="cam2camCloseButton"
            >
              &#xE8BB;
            </button>
          </div>
        </div>
        <div
          className="localVideoDebug"
          style={{ display: "none", marginTop: "20px" }}
        >
          {this.state.debugmsg}
        </div>
        <video
          ref={this.videoElement}
          width="100%"
          height="100%"
          playsInline={true}
          muted={true}
          controls
        ></video>
      </div>
    );
  }
}

export default Cam2CamVideoSoup;