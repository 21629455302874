import React, { FormEvent } from 'react';
import AppContext from './AppContext';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { LocalStorageWorker } from './StorageHelper';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faBluetooth, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { ButtonGroup } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import { Messenger } from './components/Messenger';
import './components/styles/Messenger.css';
import DraggableModalDialog from './draggable'
const API_BASE = 'https://api.feel-app.com/api/v1';
export interface MessengerModalProps {
    language: string;
    closeCallback: () => void;
    authtoken: string;
    producerID: string;

}

interface MessengerModal {
    state: MessengerModalState;
    props: MessengerModalProps;
    producerID: string;
    authtoken: string;
    colormode: string;
    closeCallback: () => void;
}


interface MessengerModalState {
    language: string;
    initializing: boolean;
    fetching: boolean;
    producerID: string;
    authtoken: string;
    myColorMode: string;

}


declare global {
    interface Window {
        initializekiiroo(authToken: string, uid: string): void;
    }
}

class MessengerModal extends React.Component {
    static contextType = AppContext;

    constructor(props: MessengerModalProps) {
        super(props);
        this.closeCallback = props.closeCallback;
        this.state = {
            language: props.language,
            initializing: true,
            fetching: false,
            producerID: props.producerID,
            authtoken: props.authtoken,
            myColorMode: "",

        }


    }
    componentDidMount() {
        let myLocalStorage: LocalStorageWorker = new LocalStorageWorker();
        let myColorMode: string = myLocalStorage.get("colorMode");
        this.setState({ myColorMode: myColorMode });
    }
    getStyleByMode() {
        const { myColorMode } = this.state;
        let styles = {
            backgroundColor: 'rgb(40, 39, 39)',  // Standard Hintergrundfarbe
            color: 'white'                       // Standard Textfarbe
        };

        switch (myColorMode) {
            case 'white':
                styles = { backgroundColor: 'white', color: 'black' };
                break;
            case 'gray':
                styles = { backgroundColor: 'grey', color: 'white' };
                break;
            case 'sepia':
                styles = { backgroundColor: '#BCAC80', color: '#000' };
                break;

        }

        return styles;
    }

    render() {
        return (
            <Modal enforceFocus={false} show={true} onHide={this.closeCallback.bind(this)} dialogAs={DraggableModalDialog} dialogProps={{ cursor: 'grab' }} backdrop={true}>
                <Modal.Header style={this.getStyleByMode()} closeButton>
                    <Modal.Title style={this.getStyleByMode()}>Messenger</Modal.Title>
                </Modal.Header>
                <Modal.Body style={this.getStyleByMode()}>
                    <Messenger
                        authtoken={this.state.authtoken}
                        producerID={this.state.producerID}
                    >
                    </Messenger>
                </Modal.Body>
                <Modal.Footer  style={this.getStyleByMode()}>

                </Modal.Footer>
            </Modal>
        )
    }
}

export default MessengerModal;