import React, { useState, useCallback, useRef, useEffect,useContext } from "react";
import { Line } from "rc-progress";
import S3FileUpload from 'react-s3';
import Evaporate from 'evaporate';
import AppContext from "../AppContext";
import { uploadFile } from 'react-s3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
 
const config = {
    bucketName: 'guppyvideoupload',
    dirName: '', /* optional */
    region: 'eu-west-1',
    accessKeyId: 'AKIAITVOVDQDNGGW4ORA',
    secretAccessKey: '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',
}

const VideoUpload = (props) => {

  const [authToken, setAuthToken] = useState(props.authToken);
  const [uploadType, setUploadType] = useState(props.uploadType);
  const [uploadFileName, setUploadFileName] = useState("");
  const [progress, setProgress] = useState(0);
  const context = useContext(AppContext);
  const producerID = context.loginData ? context.loginData.producerID : ''; 
  const [selectedFile, setSelectedFile] = useState(null);
  

  let progressCounter = 0;

  const handleFileInput = (e) => {
      setSelectedFile(e.target.files[0]);
      setProgress(0);
      console.log("File selected for videoupload: " + e.target.files[0].name);
      setUploadFileName(e.target.files[0].name);
  }

  const handleUpload = async (file) => {
      /*uploadFile(file, config)
          .then(data => console.log(data))
          .catch(err => console.error(err))*/

      let jsonURL = "https://api.deine.fans/api/videoupload?producerID=" + encodeURIComponent(producerID) + "&authToken=" + encodeURIComponent(authToken);

      jsonURL += "&uploadType=" + encodeURIComponent(uploadType);
      jsonURL += "&uploadFileName=" + encodeURIComponent(uploadFileName);

      fetch(jsonURL,{method:"GET"})
          .then(async (response) => {
            const crypto = require('crypto');

            let data = await response.json();

            console.log("new upload key: " + data.uploadkey);

            var config = {
                  signerUrl: "https://api.deine.fans/api/evaporate",
                  aws_key: 'AKIAITVOVDQDNGGW4ORA',
                  bucket: 'guppyvideoupload',
                  cloudfront: false,
                  s3Acceleration: true,
                  progressIntervalMS: 1000,
                  awsSignatureVersion: '2',
                  computeContentMd5: false
            };
            
            return Evaporate.create(config)
                .then(function (evaporate) {

                  let addConfig = {
                      name: data.uploadkey,
                      file: file,
                      started: function (file_key) { 
                        setProgress(1);
                        progressCounter = 0;
                        console.log('Started file upload', file_key);
                      },
                      cancelled: function () { console.log('File upload cancelled!'); },
                      error: function (msg) { console.error('File upload error!',msg); },
                      progress: function (progressValue) { 
                        console.log('Progress', progressValue); 
                        if(!isNaN(progressValue)) {
                          progressCounter++;
                          setProgress(Math.round(progressValue * 100));
                          //setProgress(Math.round(Math.round(progressValue * 10000) / 100));
                        }
                      },
                      complete: function (_xhr, awsKey) { 
                        setProgress(100);
                        console.log('File Upload Complete!');
                      },
                    },
                    overrides = {
                      bucket: 'guppyvideoupload' // Shows that the bucket can be changed per
                    };

                  evaporate.add(addConfig, overrides)
                      .then(async function (awsObjectKey) {
                            console.log('File successfully uploaded to:', awsObjectKey);
                            let jsonURL = "https://api.deine.fans/api/videoupload?producerID=" + encodeURIComponent(producerID) + "&authToken=" + encodeURIComponent(authToken) + "&uploadType=" + encodeURIComponent(uploadType) + "&uploadkey=" + encodeURIComponent(awsObjectKey);

                            let response = await fetch(jsonURL,{method:"PUT"})
                            let data = await response.json();

                            console.log("New streamname created: " + data.streamname);

                            props.completeCallback();
                          },
                          function (reason) {
                            console.log('File did not upload sucessfully:', reason);
                          });
                });
          })
          .catch(err => console.error(err))
  }

  return <div className="videoUploadDIV">
      <h5>Upload Video</h5>
      <input class="formFile d-inline-block" color="secondary" type="file" accept="video/*" onChange={handleFileInput} />
      <button onClick={() => handleUpload(selectedFile)}> Start Upload</button>
      { progress > 0 ? 
      <div className="videoUploadProgressDIV">
          <div style={{position: "absolute",zIndex: 1,color: "#333",textAlign: "center",width: "420px",paddingTop: "2px"}}>{progress}%</div>
          <Line
              style={{ height: "20px", marginTop: "0px",position: "relative" }}
              strokeWidth={5}
              strokeColor={progress === 100 ? "#00a626" : "#2db7f5"}
              percent={progress}
          />
      </div> : null }
  </div>
}

export default VideoUpload;